<template>
  <div>
    <div class="alert">
      <a-alert
        v-if="isAlert"
        message="Successful registration, verification email has been sent to your mailbox, please verify within 48 hours"
        type="success"
        closable
        :after-close="handleClose"
      />
    </div>
    <div class="login">
      <div class="login-left">
        <div class="content">
          <r-link to="/">
            <logo class="logo" />
          </r-link>
          <h6>Welcome back</h6>
          <!-- <a-form
            id="components-form-demo-normal-login"
            :form="form"
            class="login-form"
          > -->
          <a-form-item>
            <input-text ref="username" placeholders="Email" error-msg="Email is Required" @setValue="getValue('username',$event)" />
            <!-- <a-input
                v-decorator="[
                  'userName',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your username!'
                      }
                    ]
                  }
                ]"
                size="large"
                placeholder="Username"
              >
                <a-icon
                  slot="prefix"
                  type="user"
                  style="color: rgba(0,0,0,.25)"
                />
              </a-input> -->
          </a-form-item>
          <a-form-item>
            <input-text ref="password" placeholders="Password" error-msg="Password is Required" type="password" @setValue="getValue('password',$event)" />
            <!-- <a-input
                v-decorator="[
                  'password',
                  {
                    rules: [
                      { required: true, message: 'Please input your Password!' }
                    ]
                  }
                ]"
                size="large"
                type="password"
                placeholder="Password"
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  style="color: rgba(0,0,0,.25)"
                />
              </a-input> -->
          </a-form-item>
          <reCaptcha
            :sitekey="recaptchaKey"
            @getValidateCode="getValidateCode"
          />
          <a-form-item>
            <a-button
              :loading="iconLoading"
              style="margin-top: 20px; width: 100%; background: #f1f1f1"
              type="none"
              html-type="submit"
              class="login-form-button"
              :class="getBtnIsDisabled ? 'btn-disabled' : ''"
              @click="handleSubmit"
            >Log in</a-button>
            <p class="login-form-forgot" @click="goForgot">Forgot password</p>
            <p class="newto">
              New to {{ appName }}app?
              <a @click="callNewPage('/register')">Sign Up</a>
            </p>
          </a-form-item>
          <!-- </a-form> -->
        </div>
      </div>
      <common-right />
    </div>
  </div>
</template>
<script>
import reCaptcha from '@/components/ReCaptcha'
import { mapGetters, mapMutations } from 'vuex'
import { Login } from '@/api/user'
import { currentMerchant } from '@/api/merchant'
import inputText from '@/components/inputText'

export default {
  components: {
    reCaptcha,
    inputText
  },
  computed: {
    ...mapGetters('User', {
      appName: 'getAppName'
    }),
    getBtnIsDisabled() {
      if (!this.username || !this.password) {
        return true
      } else {
        return false
      }
    }
  },
  data() {
    return {
      iconLoading: false,
      isAlert: false,
      recaptchaKey: process.env.VUE_APP_RECAPTCHAkEY, // recaptcha的key
      isRecaptcha: '', // 验证是否通过判断是不是等于空
      // username: "wuwenyaoiscool@gmail.com",
      username: '',
      password: '',
      // password: "123456",
      redomStr: '',
      validateCode: false,
      token: ''
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal_login' })
    console.log(this.form)
  },
  mounted() {
    const isAlert = this.$route.query.isAlert
    if (isAlert) {
      this.isAlert = true
      setTimeout(() => {
        this.isAlert = false
      }, 5000)
    }
  },
  methods: {
    ...mapMutations('User', [
      'Tokenmutations',
      'loginMsgmutations',
      'merchantInfoMutations',
      'shouldDashboardNoticeMutations'
    ]),
    getValue(name, value) {
      console.log('getValue...', name, '----value----', value)
      this[name] = value
      console.log('this[name]...', this.username)
    },
    // 获取recaptcha的token
    getValidateCode(value) {
      this.isRecaptcha = value
    },
    goForgot() {
      this.callNewPage('/resetPwd')
    },
    async handleSubmit(e) {
      this.iconLoading = true
      e.preventDefault()
      console.log('this.$refs.username.validate()...', this.$refs.username.validate())
      console.log('this.$refs.password.validate()...', this.$refs.password.validate())
      if (this.$refs.username.validate() && this.$refs.password.validate()) {
        if (!this.isRecaptcha) {
          this.iconLoading = false
          this.$message.error('Please verify reCaptcha')
          return
        }
        // if (!err) {
        const { code, msg } = await Login({
          recaptchaToken: this.isRecaptcha,
          username: this.username,
          password: this.password
        })
        if (code === 200) {
          this.loginMsgmutations({
            username: this.username,
            password: this.password
          })
          this.iconLoading = false
          var toPath = this.$route.query['redirect'] || '/'
          const { data } = await currentMerchant()
          this.merchantInfoMutations(data)
          this.shouldDashboardNoticeMutations(true)
          this.callNewPage(toPath)
        } else {
          this.iconLoading = false
          window.grecaptcha.reset()
          this.$message.error(msg)
        }
      } else {
        this.iconLoading = false
      }
    }
  }
}
</script>
<style lang="scss">
.login .login-form-button{
  background-color: #1f1f1f !important;
  height: 48px;
  line-height: 48px;
  border-radius: 8px;
  font-size: 16px;
  color: white !important;
  z-index: 10;
  cursor: pointer;
  &:hover{
    background-color: rgba(31, 31, 31,0.8) !important;
  }
}
</style>
<style scoped lang="scss">
@import "../../assets/css/user.css";

.content{
  width: 456px;
  max-width: 456px !important;
}
.login{
  background: white;
  position: fixed;
  height: 100%;
  width: 100%;
}
h6{
  margin-bottom: 30px;
}
.alert {
  width: 1000px;
  margin: 0 auto;
}
p {
  margin: 0;
  padding: 0;
}
.login-form-forgot {
  color: #1f1f1f;
  font-size: 16px;
  cursor: pointer;
}

.newto{
  font-size: 16px;
  color: #1f1f1f;
  a{
    color: #1f1f1f;
    text-decoration: underline;
  }
}
.btn-disabled{
  background-color: RGBA(89, 89, 89, 1) !important;
}
</style>
